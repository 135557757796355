




.international-location {
	margin-bottom:3rem;
	text-align: center;
	.flag {
		margin-bottom: 1rem;
	}
	.org {
		margin-bottom: 1rem;
	}
	.address {
		margin-bottom: 1rem;
	}
	.address-line {
		//font-size:rem-calc(13px);
		//text-transform: uppercase;
		//font-weight: 700;
	}
	.tel {
		margin-top: 1rem;
	}
}







[data-grid="locations-grid"] {

	@include grid-row-nest();
	overflow: auto;

	@include grid-layout(1, ".international-location", $grid-column-gutter);

	@include breakpoint(480) {
		@include grid-layout(2, ".international-location", $grid-column-gutter);
	}

	@include breakpoint(720) {
		@include grid-layout(3, ".international-location", $grid-column-gutter);
	}
  @include breakpoint(960) {
    @include grid-layout(4, ".international-location", $grid-column-gutter);
  }
}
