




// NAVIGATION IMAGE GRID LAYOUT
[data-grid*="navigation-image-grid"] {
	@include grid-layout(1, '.navigation-image-card');
	@include breakpoint(480) {
		@include grid-layout(2, '.navigation-image-card');
	}
	@include breakpoint(1024) {
		@include grid-layout(4, '.navigation-image-card');
	}
}

// NAVIGATION IMAGE CARD
.navigation-image-cell {
	overflow:hidden;
    width: 100%;
    @include aspect-ratio(3, 2);
	img,
	.block-content {
		position: absolute;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  background-color: rgba(0,0,0,0.2);
		height:100%;
	}
	.vcenter {
		width: 100%;
		padding:1rem;
		color:white;
		text-align: center;
		@include vertical-center();
	}
	img {transition: all .2s ease-in-out;}
	&:hover img { transform: scale(1.2); }

	.title {
		@extend h4;
		font-size:rem-calc(16px);
		text-transform: uppercase;
	}
}
