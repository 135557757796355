
footer {
	background-color:#000;
	color:#999;
	padding:1rem 0;


	a {
		color:white;
	}
	.row {
		padding:1rem 0;
	}


	nav.footer-links {
		ul {
			@extend .no-bullet;
			margin:0;
		}
		li {
			font-size:rem-calc(12px);
			font-family: $header-font-family;
			font-weight:700;
			text-transform: uppercase;
			padding:0 0.5rem 0.5rem 0;
		}
	}

	nav.meta-links {
		ul {
			@extend .no-bullet;
			margin:0;
			padding:0;

		}
		li {
			display:inline-block;
			font-size:rem-calc(12px);
			font-family: $header-font-family;
			font-weight:700;
			text-transform: uppercase;
			padding:0 0.5rem 0.5rem 0;
		}
	}

	address {
		font-size:rem-calc(13px);
		font-style:inherit;
		font-weight:700;
		text-transform: uppercase;
		font-family: $header-font-family;
		.org {
			padding-bottom:0.5rem;
		}

        .address-line,
        .tel,
        .email {
 			display: inline-block;
			padding-right: 0.5rem;
			padding-bottom:0.5rem;
			font-weight:700;
        }
	}


	.supagard-app {
		text-align:center;
		font-size:rem-calc(12px);
		font-family: $header-font-family;
		font-weight:700;
		text-transform: uppercase;
		.app-title {}
		.app-badge {
			display:block;
			padding-bottom:0.5rem;
		}
	}

}
