.breadcrumbs {
  padding-top:1rem;
  font-family: $header-font-family;
  font-weight:700;
}


[data-row-type="breadcrumbs"] {
  background-color: black;
}
