
.logo-grid {
	@include grid-row-nest();
	overflow: auto;
  .logo-item {
    margin-bottom:2rem;
  }
	@include grid-layout(3, ".logo-item", $grid-column-gutter);

	@include breakpoint(600) {
		@include grid-layout(4, ".logo-item", $grid-column-gutter);
	}

	@include breakpoint(800) {
		@include grid-layout(9, ".logo-item", $grid-column-gutter);
	}
}
