// ASPECT RATIO FOR IMAGES / KEEPS WIDTH / HEIGHT

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }
    > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}


@mixin vertical-center-table {
   display: table;
   height: 100%;
   > * {
       display: table-cell;
       vertical-align: middle;
       height: 100%;
       > * {
           margin: 0 auto;
           height: 100%;
       }
   }
}

@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}
