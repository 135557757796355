

.news-grid-item {
	padding-bottom:3rem;
	.news-grid-summary {
		padding-top: 1rem;
	}
	h1 {
		padding-top: 1rem;
		font-size: rem-calc(18px);
	}
}



.news-grid {
	@include grid-row-nest();
	@include grid-layout(1, ".news-grid-item", $grid-column-gutter);

	@include breakpoint(600) {
		@include grid-layout(2, ".news-grid-item", $grid-column-gutter);
	}

	@include breakpoint(800) {
		@include grid-layout(3, ".news-grid-item", $grid-column-gutter);
	}
}
