.staff-profile-item {

	.staff-profile-content {
		padding:0 0 3rem 0;
		position:relative;
	}

	.profile-image {
		position:relative;
	}
	&:hover .profile-image {
		&::after  {
			background-color: rgba(0,0,0,0.5);
		}
	}

	img {
		margin:0 0 1rem 0;
		display:block;
	}

	.profile-details {

	}

	.profile-bio {
		padding:0.5rem;
	}




	.profile-name {
		margin:0 0.5rem 0.5rem;
	}
	.profile-position {
		//font-family:$header-font-family;
		font-size:rem-calc(14px);
		font-weight:700;
		text-transform:uppercase;
		margin:0 0.5rem 0.5rem;
	}
	.profile-social {
		margin:0 0.5rem 0.5rem;
		a {
			margin-right:0.5rem;
			i {
				@extend .fa-lg;
			}
		}
	}
	.profile-email {
		margin:0 0.5rem 0.5rem;
		// &::after {
		// 	content: attr(data-domain);
		// }
		// &::before {
		// 	content: attr(data-user) "\0040";
		// }
		a {
			word-wrap: break-word;
		}
	}
	.profile-tel {
		margin:0 0.5rem 0.5rem;
	}
	@include breakpoint(940) {
		.profile-bio {
			@include aspect-ratio(1,1);
			//@include transition(all 0.3s ease-in-out);
			transition: all 0.3s ease-in-out;
			position:absolute;
			top:0;
			width:100%;
			opacity:0;
			overflow:hidden;
			background-color: rgba($primary-color,0.5);
			> .content {
				//@include transition(all 0.3s ease-in-out);
				transition: all 0.3s ease-in-out;
				top:40%;
				padding:1rem;
				color:white;
			}

			&:hover {
				// /@include transition(all 0.3s ease-in-out);
				transition: all 0.3s ease-in-out;
				opacity:1;
				cursor:help;
				> .content {
					top:20%;
				}
			}
		}
	}
}



.staff-profiles {
	@include grid-row-nest();
	@include grid-layout(1, ".staff-profile-item", $grid-column-gutter);

	@include breakpoint(480) {
		@include grid-layout(2, ".staff-profile-item", $grid-column-gutter);
	}

	@include breakpoint(medium) {
		@include grid-layout(3, ".staff-profile-item", $grid-column-gutter);
	}
}



[data-grid*="staff-profiles"] {
	@include grid-row-nest();
	@include grid-layout(1, ".staff-profile-item", $grid-column-gutter);

	@include breakpoint(480) {
		@include grid-layout(2, ".staff-profile-item", $grid-column-gutter);
	}

	@include breakpoint(medium) {
		@include grid-layout(3, ".staff-profile-item", $grid-column-gutter);
	}
}
