

[data-equalizer-watch] {
	@include breakpoint(medium) {
		> .vertical-center {
			@include vertical-center;
		}
	}
}

.cellpadding {
	padding:2rem;
}


// .card {
// 	border:none;
// 	background:transparent;
// 	.card-section {
// 		padding:2rem;
// 	}
// 	.card-title {
// 		text-transform:uppercase;
// 	}
// }
//
// .column-class {
// 	height:100%;
// 	//background-color:red;
// 	img {
// 		width:100%;
// 		height:100%;
// 	}
// }


.object-fit_fill { object-fit: fill }
.object-fit_contain { object-fit: contain }
.object-fit_cover { object-fit: cover }
.object-fit_none { object-fit: none }
.object-fit_scale-down { object-fit: scale-down }







.card-feature {
	img {
		margin-bottom:1rem;
	}
}
