  //@import 'bourbon'; // http://bourbon.io/

  //@import '../partials/variables'; // colors, fonts etc...


// #0085CA - LIGHT BLUE
// #004F71 DARK BLUE

  // colors

  $color-1: #2e3233; // grey dark
  $color-1: #000; // grey dark
  $color-2: #69aa6f; // green
  $color-2: #df3439; // green
  $color-3: #e2e3df; // grey light
  $color-4: #ffffff; // white

  // fonts

  $primary-font: 'Montserrat', sans-serif;

  // header size

  $header-height-S: 50px;
  $header-height-L: 120px;

  // Navigation size

  $nav-width-S: 260px;

  // Search size

  $search-height-S: 50px;
  $search-height-L: 120px;

  // z-index

  $below-content: 1;
  $content: 2;
  $above-content: 3;




  //////////////////////////////////////////////////////////////////////
  /////////////             STYLE SETTINGS         /////////////////////

$talon-dark-blue:#004F71;
$talon-light-blue:#0085CA;


$cd-main-content:white;
$cd-main-header-background-color: #000;



  $nav-body-color:#000;
  $nav-body-bg-color:#000;

  $nav-text-color:#fff;
  $nav-link-color:#ffffff;
  $nav-link-color-hover:$talon-light-blue;

  $nav-color-M:#000;
  $nav-color-L:#000;

  $nav-link-color-S:#fff;
  $nav-link-color-M:#000;
  $nav-link-color-L:#000;

  $nav-link-color-hover-S:#000;
  $nav-link-color-hover-M:#000;
  $nav-link-color-hover-L:#000;

$search-trigger-color:blue;

 $nav-trigger-color:white;







  ///////////////////////////////////////////////////
  //@import '../partials/layout'; // responsive grid and media queries
  // breakpoints

  $S:     480px;
  $M:     768px;
  $L:     1170px;
  $maxL:    $L - 1px;

  // media queries

  @mixin MQ($canvas) {
    @if $canvas == S {
      @media only screen and (min-width: $S) { @content; }
    }
    @else if $canvas == M {
      @media only screen and (min-width: $M) { @content; }
    }
    @else if $canvas == L {
      @media only screen and (min-width: $L) { @content; }
    }
    @else if $canvas == maxL {
      @media only screen and (max-width: $maxL) { @content; }
    }


  }

  // super light grid - it works with the .cd-container class inside style.scss

  @mixin column($percentage, $float-direction:left) {
    width: 100% * $percentage;
    float: $float-direction;

    // margin:0 $nav-gutter 40px 0;

    // &:nth-child(3n+2) {
    // 	/* +2 because we have two additional list items with display:none */
    // 	margin-right: 0;
    // }

  }




  ///////////////////////////////////////////////////////////////////////
  /* --------------------------------

  Primary style

  -------------------------------- */

  *, *::after, *::before {
    box-sizing: border-box;
  }

  // html {
  // 	font-size: 62.5%;
  // }

  body {
    // font: {
    // 	size: 1.6rem;
    // 	family: $primary-font; // variables inside partials > _variables.scss
    // }
  //  color: $color-1;
  //  background-color: $color-4;

    @include MQ(maxL) { // layout mixins inside partials > _layout.scss
      &.nav-on-left.overflow-hidden {
        overflow: hidden;
      }
    }
  }

  a {
    //color: $color-2;
    text-decoration: none;
  }

  img {
    /* make images responsive */
    max-width: 100%;
  }

  input {
    font-family: $primary-font;
    font-size: 1.6rem;
  }

  input[type="search"]::-ms-clear {
    /* removes close icon - IE */
    display: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  /* --------------------------------

  Main components

  -------------------------------- */

  .cd-main-content, .cd-main-header {
    /* Force Hardware Acceleration in WebKit */
    //@include transform(translateZ(0));
    //transform: translateZ(0);
    //will-change: transform;
  }

  .cd-main-content, .cd-main-header {
    position: relative;
    //@include transition(transform .3s);
    transition: transform .3s;

    @include MQ(maxL) {
      &.cd-nav-is-visible {
        //@include transform(translateX(-$nav-width-S));
        transform: translateX(-$nav-width-S);
      }

      .nav-on-left &.cd-nav-is-visible {
        //@include transform(translateX($nav-width-S));
        transform: translateX($nav-width-S);
      }
    }
  }

  .cd-main-content {
    //background: $color-3;
    background: $cd-main-content ;  // NEEDED FOR OVERLAY // MCG
    min-height: 100vh;
    z-index: $content;
  }

  .cd-main-header {
    height: $header-height-S;
    // background: $color-4;
    background-color: $cd-main-header-background-color;
    z-index: $above-content;

    .nav-is-fixed & {
      /* add .nav-is-fixed class to body if you want a fixed navigation on > 1170px */
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    @include MQ(L) {
      height: $header-height-L;
      @include clearfix;
    }

  }


  /////////////////////// LOGO ////////////////////////////////////////


  .cd-logo {
    position: absolute;
    top: 12px;
    left: 5%;

    img {
      display: block;
      height:30px;
    }

    @include MQ(maxL) {
      .nav-on-left & {
        left: auto;
        right: 5%;
      }
    }

    @include MQ(L) {
      //top: 26px;
      left: 4em;
      img {
        height:auto;
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////






  ///////////////////////// NAV BUTTONS - SEARCH AND TRIGGER //////////
  .cd-header-buttons {
    position: absolute;
    display: inline-block;
    top: 3px;
    right: 5%;

    li {
      display: inline-block;
    }

    @include MQ(maxL) {
      .nav-on-left & {
        right: auto;
        left: 5%;

        li {
          float: right;
        }
      }
    }

    @include MQ(L) {
      top: 18px;
      right: 4em;
    }
  }
  //////////////////////////////////////////////////////////////////////////////



  /// MCG _ MOVED INTO EACH CLASS
  // .cd-search-trigger, .cd-nav-trigger {
  // 	position: relative;
  // 	display: block;
  // 	width: 44px;
  // 	height: 44px;
  // 	overflow: hidden;
  // 	white-space: nowrap;
  // 	/* hide text */
  // 	color: transparent;
  // 	z-index: $above-content;
  // }
  //







  /////////////////////////// SEARCH TRIGGER ////////////////////////////////////


  .cd-search-trigger {
    position: relative;
    display: block;
    width: 44px;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    /* hide text */
    color: transparent;
    z-index: $above-content;


    &::before, &::after {
      /* search icon */
      content: '';
      position: absolute;
      //@include transition(opacity .3s);
      transition: opacity .3s;
      /* Force Hardware Acceleration in WebKit */
      //@include transform(translateZ(0));
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    &::before {
      /* lens */
      top: 11px;
      left: 11px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      // border: 3px solid $color-1;
      border: 3px solid $search-trigger-color;
    }

    &::after {
      /* handle */
      height: 3px;
      width: 8px;
      // background: $color-1;
      background: $search-trigger-color;
      bottom: 14px;
      right: 11px;
      //@include transform(rotate(45deg));
      transform: rotate(45deg);
    }

    span {
      /* container for the X icon */
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }

    span::before, span::after {
      /* close icon */
      content: '';
      position: absolute;
      display: inline-block;
      height: 3px;
      width: 22px;
      top: 50%;
      margin-top: -2px;
      left: 50%;
      margin-left: -11px;
      //background: $color-1;
      background: $search-trigger-color;
      background: white;
      opacity: 0;
      /* Force Hardware Acceleration in WebKit */
      //@include transform(translateZ(0));
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      //@include transition(opacity .3s, transform .3s);
      transition: opacity .3s, transform .3s;
    }

    span::before {
      //@include transform(rotate(45deg));
      transform: rotate(45deg);
    }

    span::after {
      //@include transform(rotate(-45deg));
      transform: rotate(-45deg);
    }

    &.cd-search-is-visible {
      &::before, &::after {
        /* hide search icon */
        opacity: 0;
      }

      span::before, span::after {
        /* show close icon */
        opacity: 1;
      }

      span::before {
        //@include transform(rotate(135deg));
        transform: rotate(135deg);
      }

      span::after {
        //@include transform(rotate(45deg));
        transform: rotate(45deg);
      }
    }
  }
  ///////////////////////////////////////////////////////////////////////////////




  ///////////////      NAV TRIGGER - HAMBURGER      ///////////////////////////
  .cd-nav-trigger {
    position: relative;
    display: block;
    width: 44px;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    /* hide text */
    color: transparent;
    z-index: $above-content;

    &:hover, &:focus {
          color: transparent;
    }
    span, span::before, span::after {
      /* hamburger icon in CSS */
      position: absolute;
      display: inline-block;
      height: 3px;
      width: 24px;
      //background: $color-1;
      background: $nav-trigger-color;
    }

    span {
      /* line in the center */
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -2px;
      //@include transition(background .3s .3s);
      transition: background .3s .3s;
    }

    span::before, span::after {
      /* other 2 lines */
      content: '';
      right: 0;
      /* Force Hardware Acceleration in WebKit */
      //@include transform(translateZ(0));
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      //@include transform-origin(0% 50%);
      transform-origin: 0% 50%;
      //@include transition(transform .3s .3s);
      transition: transform .3s .3s;
    }

    span::before {
      /* menu icon top line */
      top: -6px;
    }

    span::after {
      /* menu icon bottom line */
      top: 6px;
    }

    &.cd-nav-is-visible {
      span {
        /* hide line in the center */
        //background: rgba($color-1, 0);
        background: rgba($nav-trigger-color, 0);
      }

      span::before, span::after {
        /* keep visible other 2 lines */
        //background: rgba($color-1, 1);
        background: rgba($nav-trigger-color, 1);
      }

      span::before {
        //@include transform(translateX(4px) translateY(-3px) rotate(45deg));
        transform: translateX(4px) translateY(-3px) rotate(45deg);
      }

      span::after {
        //@include transform(translateX(4px) translateY(2px) rotate(-45deg));
        transform: translateX(4px) translateY(2px) rotate(-45deg);
      }
    }

    @include MQ(L) {
      display: none;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////




















  // NAV BAR /////////////////////////////////////////////////////
  .cd-primary-nav, .cd-primary-nav ul {

    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: $nav-width-S;
    background: $color-1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: $below-content;
    /* Force Hardware Acceleration in WebKit */
    //@include transform(translateZ(0));
    transform: translateZ(0);

    //@include transform(translateX(0));
    transform: translateX(0);
    //@include transition(transform .3s);
    transition: transform .3s;

    list-style: none;   // MCG remove bullets
    margin:0;//MCG reset foundation margins:

    font-family: $primary-font;
        letter-spacing: 0.025rem;

    a {
      display: block;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      color: $color-4;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-bottom: 1px solid lighten($color-1, 5%);
      border-bottom: 1px solid #222;
      //@include transform(translateZ(0));
      transform: translateZ(0);
      will-change: transform, opacity;
      //@include transition(transform .3s, opacity .3s);
      transition: transform .3s, opacity .3s;

      text-transform: uppercase; // MCG

      font-size:rem-calc(14px); //MCG
      font-weight:700; //MCG



       p {
         text-transform: none;//MCG
         font-weight:400; //MCG
         font-family: $body-font-family;
       }


    }

    &.cd-is-hidden {
      /* secondary navigations hidden by default */
      //@include transform(translateX(100%));
      transform: translateX(100%);
    }

    &.moves-out > li > a {
      /* push the navigation items to the left - and lower down opacity - when secondary nav slides in */
      //@include transform(translateX(-100%));
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  @include MQ(maxL) {
    .nav-on-left .cd-primary-nav, .nav-on-left .cd-primary-nav ul {
      right: auto;
      left: 0;
    }
  }









  // SUBPAGES  MOBILE ///////////////////////////////////////////////////////////////////

  .cd-primary-nav {

    .see-all a {
      /* different style for the See all button on mobile and tablet */
      color: $color-2;
      color: white;
    }

    .cd-nav-gallery .cd-nav-item, .cd-nav-icons .cd-nav-item, .cd-nav-icons-feature .cd-nav-item {
      /* items with picture (or icon) and title */
      // height: 80px; //MCG
      // line-height: 80px;//MCG

      div {
        overflow: hidden;
        text-overflow: ellipsis;

        //font-size: rem-calc(16px);//MCG
        color: $color-2; //MCG
        color: white;

      }
    }

    .cd-nav-gallery .cd-nav-item {
      padding-left: 90px;

      img {
        position: absolute;
        display: block;
        height: 40px;
        width: auto;
        left: 20px;
        top: 50%;
        margin-top: -20px;
      }
    }

    .cd-nav-icons .cd-nav-item {
      padding-left: 75px;


      ///////////////////////////// MCG
      background-repeat: no-repeat;
      background-position: 25px center;
      //background-size: 40px 40px, 0px 0px;
      padding-left: 20px;
      background-position: 0 center;
      //background-size: 0 0, 0 0;
      ///////////////////////////// MCG


      p {
        color: $color-1;
        color: white; //MCG
        //mcg font-size: 1.3rem;
        /* hide description on small devices */
        display: none;
      }




      // &::before {
      // 	/* item icon */
      // 	content:'';
      // 	display: block;
      // 	position: absolute;
      // 	left: 20px;
      // 	top: 50%;
      // 	margin-top: -20px;
      // 	width: 40px;
      // 	height: 40px;
      // 	background-repeat: no-repeat;
      // 	background-position: center center;
      // 	background-size: 40px 40px;
      // }

      // &.item-1::before {
      // 	background-image: url('../img/line-icon-1.svg');
      // }
      //
      // &.item-2::before {
      // 	background-image: url('../img/line-icon-2.svg');
      // }
      //
      // &.item-3::before {
      // 	background-image: url('../img/line-icon-3.svg');
      // }
      //
      // &.item-4::before {
      // 	background-image: url('../img/line-icon-4.svg');
      // }
      //
      // &.item-5::before {
      // 	background-image: url('../img/line-icon-5.svg');
      // }
      //
      // &.item-6::before {
      // 	background-image: url('../img/line-icon-6.svg');
      // }
      //
      // &.item-7::before {
      // 	background-image: url('../img/line-icon-7.svg');
      // }
      //
      // &.item-8::before {
      // 	background-image: url('../img/line-icon-8.svg');
      // }



    }


    .cd-nav-icons-feature .cd-nav-item, .cd-nav-icons .cd-nav-item {
      padding-left: 75px;




      ///////////////////////////// MCG
      background-repeat: no-repeat;
      background-position: 25px center;
      background-size: 40px 40px, 0px 0px;
      background-size: 40px 40px;
      padding-left: 20px;
      // background-position: 0 center;
      background-size: 0 0, 0 0;
      ///////////////////////////// MCG

      p {
        color: $color-1;
        color: white; //MCG
        //mcg font-size: 1.3rem;
        /* hide description on small devices */
        display: none;
        padding-top: 1rem;
      }

    }



    @include MQ(maxL) {
      /* by default .cd-primary-nav is hidden - trick for iOS devices where you can see the navigation if you pull down */
      visibility: hidden;
      //@include transition(visibility 0s .3s);
      transition: visibility 0s .3s;

      &.cd-nav-is-visible {
        visibility: visible;
        //@include transition(visibility 0s 0s);
        transition: visibility 0s 0s;
      }
    }
  }
































  @include MQ(L) {

    .cd-primary-nav {
      position: static;
      padding: 0 150px 0 0;
      height: auto;
      width: auto;
      float: right;
      overflow: visible;
      background: transparent;
      @include clearfix;

      &.moves-out > li > a {
        /* reset mobile style */
        //@include transform(translateX(0));
        transform: translateX(0);
        opacity: 1;
      }

      ul {
        position: static;
        height: auto;
        width: auto;
        background: transparent;
        overflow: visible;
        z-index: $above-content;

        &.cd-is-hidden {
          /* reset mobile style */
          //@include transform(translateX(0));
          transform: translateX(0);
        }

        &.moves-out > li > a {
          /* reset mobile style */
          //@include transform(translateX(0));
          transform: translateX(0);
          opacity: 1;
        }
      }

      > li {
        float: left;
        margin-left: 3em;
      }

      > li > a {
        /* main navigation buttons style */
        position: relative;
        display: inline-block;
        height: $header-height-L;
        line-height: $header-height-L;
        padding: 0 10px;
        color: $color-1;
        color: white; //mCG
        overflow: visible;
        border-bottom: none;
        //@include transition(color .3s, box-shadow .3s);
        transition: color .3s, box-shadow .3s;

        &:hover {
          color: $color-2;
        }

        &.selected {
          color: $color-2;
          box-shadow: inset 0 -2px 0 $color-2;
        }
      }

      .go-back, .see-all {
        display: none;
        width:100%;
      }

      .cd-secondary-nav, .cd-nav-gallery, .cd-nav-icons, .cd-nav-icons-feature {
        /* dropdown menu style */
        position: absolute;
        top: $header-height-L;
        width: 100vw;
        background: $color-4;
        background: black;//MCG
        padding: 48px 64px 130px;
       box-shadow: inset 0 1px 0 $color-3, 0 3px 6px rgba(#000, .05);
       box-shadow: inset 0 1px 0 #222, 0 3px 6px rgba(#000, .05);
        //@include transform(translateX(0));
        transform: translateX(0);
        @include clearfix;
        -webkit-transition: opacity .3s 0s, visibility 0s 0s;
        -moz-transition: opacity .3s 0s, visibility 0s 0s;
        transition: opacity .3s 0s, visibility 0s 0s;

        &.cd-is-hidden {
          opacity: 0;
          visibility: hidden;
          -webkit-transition: opacity .3s 0s, visibility 0s .3s;
          -moz-transition: opacity .3s 0s, visibility 0s .3s;
          transition: opacity .3s 0s, visibility 0s .3s;
        }

        > .see-all {
          /* this is the BIG See all button at the bottom of the dropdown menu */
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 80px;
          width: 100%;
          overflow: hidden;
          /* reset some inherited style */
          margin: 0;
          padding: 0;

          a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            font-size: 2.2rem;
            font-weight: bold;
            text-align: center;
            line-height: 80px;
            border-top: 1px solid $color-3;
            border-top: 1px solid #222;
            /* reset some inherited style */
            border-bottom: none;
            margin: 0;
            padding: 0;
            //@include transition(color .2s, background .2s, border .2s);
            transition: color .2s, background .2s, border .2s;

            &:hover {
              background: $color-1;
              background: #222;
              border-color: $color-1;
              color: $color-4;
            }
          }
        }
      }




      /////////////////////////// SECONDARY NAV ////////////////////////////////////




      .cd-secondary-nav {

        > li {
          /* change the height according to your needs - you can even set height: auto */
          height: 340px;
          /* here you set the number of columns - use width percentage */
          @include column(.23); // see partials > _layout.scss
          margin-right: 2.66%;
          border-right: 1px solid $color-3;
          overflow: hidden;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          &:nth-child(4n+2) {
            /* +2 because we have 2 list items with display:none */
            margin-right: 0;
            border-right: none;
          }

          > a {
            /* secondary nav title */
            color: $color-2;
            font-weight: bold;
            // MCG font-size: 1.6rem;
            margin-bottom: .6em;
          }
        }

        a {
          height: 30px;
          line-height: 30px;
          padding: 0 18% 0 0;
          color: $color-1;
          border-bottom: none;
          // MCG font-size: 1.4rem;

          &:hover {
            color: $color-2;
          }
        }

        ul {
          /* Force Hardware Acceleration in WebKit */
          //@include transform(translateZ(0));
          transform: translateZ(0);
        }

        ul ul {
          /* tertiary navigation */
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          &.cd-is-hidden {
            //@include transform(translateX(100%));
            transform: translateX(100%);
          }

          .go-back {
            display: block;

            a {
              color: transparent;
            }
          }

          .see-all {
            display: block;
          }
        }

        .moves-out > li > a {
          /* push the navigation items to the left - and lower down opacity - when tertiary nav slides in */
          //@include transform(translateX(-100%));
          transform: translateX(-100%);
        }
      }

      /////////////////////////// SECONDARY NAV SQUARE             ////////////////////////////////////




      .cd-secondary-nav {

        > li {
          /* change the height according to your needs - you can even set height: auto */
          height: 340px;
          /* here you set the number of columns - use width percentage */
          @include column(.23); // see partials > _layout.scss
          margin-right: 2.66%;
          border-right: 1px solid $color-3;
          overflow: hidden;
          overflow-x: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;

          &:nth-child(4n+2) {
            /* +2 because we have 2 list items with display:none */
            margin-right: 0;
            border-right: none;
          }

          > a {
            /* secondary nav title */
            color: $color-2;
            font-weight: bold;
            // MCG font-size: 1.6rem;
            margin-bottom: .6em;
          }
        }

        a {
          height: 30px;
          line-height: 30px;
          padding: 0 18% 0 0;
          color: $color-1;
          border-bottom: none;
          // MCG font-size: 1.4rem;

          &:hover {
            color: $color-2;
          }
        }

        ul {
          /* Force Hardware Acceleration in WebKit */
          //@include transform(translateZ(0));
          transform: translateZ(0);
        }

        ul ul {
          /* tertiary navigation */
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;

          &.cd-is-hidden {
            //@include transform(translateX(100%));
            transform: translateX(100%);
          }

          .go-back {
            display: block;

            a {
              color: transparent;
            }
          }

          .see-all {
            display: block;
          }
        }

        .moves-out > li > a {
          /* push the navigation items to the left - and lower down opacity - when tertiary nav slides in */
          //@include transform(translateX(-100%));
          transform: translateX(-100%);
        }
      }

























      ////////////////////////// GALLERY //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




      .cd-nav-gallery {
        li {
          /* set here number of columns - use width percentage */
          @include column(.22);
          margin: 0 4% 40px 0;

          &:nth-child(4n+2) {
            /* +2 because we have two additional list items with display:none */
            margin-right: 0;
          }
        }

        .cd-nav-item {
          border-bottom: none;
          padding: 0;
          height: auto;
          line-height: 1.2;

          img {
            position: static;
            margin-top: 0;
            height: auto;
            width: 100%;
            margin-bottom: .6em;
          }

          div {
            color: $color-2;
            font-weight: bold;
            padding: 0 .4em;

            font-size: rem-calc(16px); //MCG

          }
        }
      }




























      ////////////// NAV ICONS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


      .cd-nav-icons {
        li {

          // 3 COLUMNS
          /* set here number of columns - use width percentage */
          // @include column(.30666666666666668);
          // margin: 0 4% 40px 0;
          //
          // &:nth-child(3n+2) {
          //   /* +2 because we have two additional list items with display:none */
          //   margin-right: 0;
          // }

          // 4 COLUMNS
          /* set here number of columns - use width percentage */
          @include column(.235);
          margin: 0 2% 40px 0;

          &:nth-child(4n+2) {
            /* +2 because we have two additional list items with display:none */
            margin-right: 0;
          }

          a {
            font-size: rem-calc(14px);
          }


        }

        .cd-nav-item {
          border-bottom: none;
          height: 80px;
          line-height: 1.2;
          padding: 32px 0 0 85px;
          position: relative;
          transition: background .3s;
          background-position: 25px center;
          background-size: 40px 40px;
          &:hover {
            background-color: lighten($color-3, 8%); //MCG
            background-color:#222; //MCG
          }

          div {
            color: $color-2;
            color: white;
            font-weight: bold;
          }

          p {
            //display: none;
          }
          &::before {
            left: 25px;
          }
        }
      }


































      ////////////////// NAV ICONS  FEATURE ///////////////////////////////////////////////////////////////////////////////////
      .cd-nav-icons-feature {
        li {
          @include column(.235);
          margin: 0 2% 40px 0;

          a {
            font-size: rem-calc(14px);
          }

          p {

          }

          &:nth-child(3), &:nth-child(4) {
            @include column(.49);
            a {
              min-height:260px;
              line-height: 1.6;
              padding: 3rem 1.5rem 0 252px;
              position: relative;
              overflow: visible;
              white-space: normal;

              //background-size: 0 0,200px 160px;
              background-size:200px 200px;
            }
            p {
              display: block;
            }
          }
          &:nth-child(4) {
            @include column(.49);
          }

          &:nth-child(4n+4) {
            margin-right: 0;
          }

        }




        .cd-nav-item {
          border-bottom: none;
          height: 80px;
          line-height: 1.2;
          padding: 32px 0 0 85px;
          position: relative;
          transition: background .3s;

          background-size: 40px 40px;
          &:hover {
            background-color: lighten($color-3, 8%); //MCG
            background-color:#222; //MCG
          }

          div {
            color: $color-2;
            color: white;
            font-weight: bold;
          }

          p {
            //display: none;
          }
          &::before {
            left: 25px;
          }
        }
      }





      /////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  }

















  .cd-has-children > a, .go-back a {
    position: relative;

    &::before, &::after {
      /* arrow icon in CSS - for element with nested unordered lists */
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -1px;
      display: inline-block;
      height: 2px;
      width: 10px;
      background: lighten($color-1, 10%);
      background: lighten(white, 10%);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    &::before {
      //@include transform(rotate(45deg));
      transform: rotate(45deg);
    }

    &::after {
      //@include transform(rotate(-45deg));
      transform: rotate(-45deg);
    }

    @include MQ(L) {
      &::before, &::after {
        background: darken($color-3, 10%);
      }

      &:hover::before, &:hover::after {
        background: $color-2;
      }
    }
  }

  .cd-has-children > a {
    padding-right: 40px;

    &::before, &::after {
      /* arrow goes on the right side - children navigation */
      right: 20px;
      //@include transform-origin(9px 50%);
      transform-origin: 9px 50%;
    }
  }

  .cd-primary-nav .go-back a {
    padding-left: 40px;

    &::before, &::after {
      /* arrow goes on the left side - go back button */
      left: 20px;
      //@include transform-origin(1px 50%);
      transform-origin: 1px 50%;
    }
  }















  @include MQ(L) {

    .cd-has-children > a {
      &::before, &::after {
        right: 15%;
      }
    }

    .cd-primary-nav > .cd-has-children > a {
      /* main navigation arrows on larger devices */
      padding-right: 30px !important;

      &::before, &::after {
        width: 9px;
        //@include transform-origin(50% 50%);
        transform-origin: 50% 50%;
        background: darken($color-3, 10%);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        //@include transition(width .3s, transform .3s);
        transition: width .3s, transform .3s;
      }

      &::before {
        right: 12px;
      }

      &::after {
        right: 7px;
      }

      &.selected::before, &.selected::after {
        width: 14px;
      }

      &.selected::before {
        //@include transform(translateX(5px) rotate(-45deg));
        transform: translateX(5px) rotate(-45deg);
      }

      &.selected::after {
        //@include transform(rotate(45deg));
        transform: rotate(45deg);
      }
    }

    .cd-secondary-nav > .cd-has-children > a::before, .cd-secondary-nav > .cd-has-children > a::after {
      /* remove arrows on secondary nav titles */
      display: none;
    }

    .cd-primary-nav .go-back a {
      padding-left: 20px;

      &::before, &::after {
        left: 1px;
      }
    }
  }























  .cd-search {
    position: absolute;
    height: $search-height-S;
    width: 100%;
    top: $header-height-S;
    left: 0;
    z-index: $above-content;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity .3s 0s, visibility 0s .3s;
    -moz-transition: opacity .3s 0s, visibility 0s .3s;
    transition: opacity .3s 0s, visibility 0s .3s;

    form {
      height: 100%;
      width: 100%;
    }

    input {
      border-radius: 0;
      border: none;
      background: $color-4;
      height: 100%;
      width: 100%;
      padding: 0 5%;
      box-shadow: inset 0 1px 0 $color-3, 0 3px 6px rgba(#000, .05);

      appearance:none;


      &::placeholder {
        color: darken($color-3, 10%);
      }

      &:focus {
        outline: none;
      }

    }

    &.cd-is-visible {
      opacity: 1;
      visibility: visible;
      -webkit-transition: opacity .3s 0s, visibility 0s 0s;
      -moz-transition: opacity .3s 0s, visibility 0s 0s;
      transition: opacity .3s 0s, visibility 0s 0s;
    }

    .nav-is-fixed & {
      position: fixed;
    }

    @include MQ(L) {
      height: $search-height-L;
      top: $header-height-L;

      input {
        padding: 0 2em;
        font-size: 3.2rem;
        font-weight: 300;
        &::placeholder {

        }
      }
    }
  }
















  .cd-overlay {
    /* shadow layer visible when navigation is active */
    position: fixed;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0,0,0, .8);
    visibility: hidden;
    opacity: 0;
    //@include backface-visibility(hidden);
    backface-visibility: hidden;
    //@include transition(opacity .3s 0s, visibility 0s .3s, transform .3s 0s);
    transition: opacity .3s 0s, visibility 0s .3s, transform .3s 0s;

    &.cd-is-visible {
      opacity: 1;
      visibility: visible;
      //@include transition(opacity .3s 0s, visibility 0s 0s, transform .3s 0s);
      transition: opacity .3s 0s, visibility 0s 0s, transform .3s 0s;
    }

    @include MQ(maxL) {
      &.cd-is-visible {
        //@include transform(translateX(-$nav-width-S));
        transform: translateX(-$nav-width-S);
      }
      .nav-on-left &.cd-is-visible {
        //@include transform(translateX($nav-width-S));
        transform: translateX($nav-width-S);
      }
      &.cd-is-visible.search-is-visible, .nav-on-left &.cd-is-visible.search-is-visible {
        //@include transform(translateX(0));
        transform: translateX(0);
      }
    }
  }


  /* --------------------------------

  support for no js

  -------------------------------- */

  .no-js .cd-primary-nav {
    position: relative;
    height: auto;
    width: 100%;
    overflow: visible;
    visibility: visible;
    z-index: $content;
  }

  .no-js .cd-search {
    position: relative;
    top: 0;
    opacity: 1;
    visibility: visible;
  }

  @include MQ(L) {
    .no-js .cd-primary-nav {
      position: absolute;
      z-index: $above-content;
      display: inline-block;
      width: auto;
      top: 0;
      right: 150px;
      padding: 0;
    }

    .no-js .nav-is-fixed .cd-primary-nav {
      position: fixed;
    }
  }








.test-bg {
// background-color: red;
  height:380px;
  &:before {
    z-index: -1;
    content:"" ;
    position: absolute;
    display: block;
    top:-120px;

    width:100%;
    height:500px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0082ca+0,004d71+100 */
    background: #0082ca; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #0082ca 0%, #004d71 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #0082ca 0%,#004d71 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #0082ca 0%,#004d71 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0082ca', endColorstr='#004d71',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  }

}
