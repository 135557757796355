

.testimonial-item {
	padding-bottom:3rem;
	.testimonial-quote {
		background-color:#eceff3;

	    position: relative;
	    padding: 4rem 2rem;
	    margin-bottom: 2rem;
	    color: #555;

		&.tri:after {
			content: "";
			position: absolute;
			bottom: -16px;
			left: calc(50% - 16px);
			border-width: 16px 16px 0;
			border-style: solid;
			border-color: #eceff3 transparent;
			display: block;
			width: 0;
		}
	}

	.testimonial-info {
		text-align:center;
	}

}



.testimonial-grid {
	@include grid-row-nest();
	@include grid-layout(1, ".testimonial-item", $grid-column-gutter);

	@include breakpoint(600) {
		@include grid-layout(2, ".testimonial-item", $grid-column-gutter);
	}

	@include breakpoint(800) {
		@include grid-layout(3, ".testimonial-item", $grid-column-gutter);
	}
}



.triangle-isosceles {
    position: relative;
    padding: 15px;
    margin: 1em 0 3em;
    color: #000;
    background: #f3961c;
}

.triangle-isosceles:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50px;
    border-width: 15px 15px 0;
    border-style: solid;
    border-color: #f3961c transparent;
    display: block;
    width: 0;
}
