
[data-row-name="email-sign-up"] {
	text-align:center;
	@include grid-row;
	.email-header {
		@include grid-col;
	}
	.email-input {
		@include grid-col;
	}
	.button-input {
		@include grid-col;
	}
	// .email-heading {
	// 	font-size:rem-calc(18px);
	// 	text-transform:uppercase;
	// 	font-weight:700;
	// }
	@include breakpoint(640) {
		.email-header {
			@include grid-column(8);
			@include grid-column-position(center);
		}
		.email-input {
			@include grid-column(6);
			@include grid-col-pos(2)
		}
		.button-input {
			text-align:left;
			@include grid-column(4);
		}
	}
}
