
.carousel-testimonials {
	//margin-bottom:1rem;

	.flickity-prev-next-button {
	  // width: 100px;
	  // height: 100px;
	  background: transparent;
		color:white;
	}
	.flickity-prev-next-button .arrow {
	  fill: $primary-color;
	}
	.flickity-prev-next-button.no-svg {
	  color: $primary-color;
	}


	.flickity-viewport {
	}
	.carousel-cell {
	  width: 100%; /* full width */
	  padding:1rem;

	  @include breakpoint(medium){
	  	width:50%
	  }
	}
	/* position dots up a bit */
	.flickity-page-dots {
	  bottom: -2rem;
		.dot {
			background: $white;
		}

	}
	.flickity-prev-next-button.previous {
    	left: -50px;
	}
	.flickity-prev-next-button.next {
    	right: -50px;
	}


	/* position dots in carousel */
	.flickity-page-dots {
		position:relative;
		bottom: 0px;
		margin:1rem 0;
	}
	/* white circles */
	.flickity-page-dots .dot {
	  width: 12px;
	  height: 12px;
	  opacity: 1;
	  background: transparent;
	  border: 2px solid $primary-color;
	}
	/* fill-in selected dot */
	.flickity-page-dots .dot.is-selected {
	  background: $primary-color;
	}






}




.testimonial-block {
	padding:0.5rem;
	.quote {
		font-style:italic;
		padding-bottom: 2rem;
	}
	.name {
		padding-bottom: 0.5rem;
	}
	.location {
		font-size: rem-calc(14px);
		font-weight: 700;
		text-transform: uppercase;
		padding-bottom: 1rem;
	}
	.button {

	}
}



.carousel-testimonials * {
	//background-color:rgba(0,0,0,0.1);
}
