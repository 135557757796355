



.carousel-image {



	// DOTS
	.flickity-page-dots {
		.dot {
			&:hover {
				opacity:0.75;
			}
			&.is-selected {
				opacity:1
			}
		}
	}

	.flickity-page-dots {
		position: relative;
		bottom:0;
		padding: 0 5%;
		display:table;
		table-layout:fixed;
		width:100%;
		.dot {
			display:table-cell;
			border-radius: 0;
			background-color: transparent;
			height:45px;
			padding:40px 0;
			&:after {
				content: "";
				display:block;
				height:5px;
				background:white;
			}
		}
	}

	@include breakpoint(medium) {
		.flickity-page-dots {
			position: absolute;
			padding: 0 5%;
			bottom:0rem;
		}
	}
}




.carousel-image {
	background: #000;
	//height:80%;
	&.flickity-enabled .carousel-cell{
		opacity: 1;
 		transition: opacity .25s ease-in-out;
	}
	.carousel-cell{
		opacity: 0;
		transition: opacity .25s ease-in-out;
	}


	.carousel-cell {
		width:100%;
	}

	img {
		width:100%;
		object-fit:cover;
		font-family: 'object-fit: cover'; // NPM OBJECT-FIT-IMAGES FOR IE
		display:block;
	}
	.carousel-content {
		width:100%;
		//height:50%;
		padding:3% 5%;
		background-color:rgba(0,0,0,1);
	}
	.content-position {
		width:100%;
	}
	// MEDIUM STYLES
	@include breakpoint(medium) {
		.carousel-cell {

		}
		img {
			max-height: calc(100vh - #{$header-height-L});
			//max-height: 50vh;
		}
		.carousel-content {
			position: absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			background-color:rgba(0,0,0,0.2);
		}
		.content-position {
			position: absolute;
			width:60%;
			&.top-middle {
				left:20%;
				text-align:center;
			}
			&.top-right {
				right:5%;
				text-align:right;
			}
			&.bottom-right {
				right:5%;
				bottom:calc(10% + 45px);
				text-align:right;
			}
			&.bottom-middle {
				left:20%;
				bottom:calc(10% + 45px);
				text-align:center;
			}
			&.bottom-left {
				bottom:calc(10% + 45px);
			}
		}
	}
}



.carousel-content {

	.headline-tag {
		@extend h6;
		color:white;
		text-transform:uppercase;
	}
	.headline {
		@extend h1;
		color:white;
		//text-transform:uppercase;
	}
	p {
		color:white;
	}
	@include breakpoint(medium) {
		.headline{
			font-size:rem-calc(24px);
		}
	}
	@include breakpoint(large) {
		.headline{
			font-size:rem-calc(48px);
		}
	}

}
