

.data-sheets {
  .title {
    i {
     padding-right:0.5rem;
    }
    padding-bottom:1rem;
  }
}

.data-sheets-grid {
  .data-sheet {
    padding-right:4rem;
    margin-bottom:1rem;
  }

	@include breakpoint(600) {
		@include grid-layout(2, ".data-sheet");
	}

	@include breakpoint(800) {
		@include grid-layout(3, ".data-sheet");
	}
}
