$row-padding:4rem;


// HEADER LAYOUT
header {
  background-color:#000;
  [data-row-type="carousel"]{
    padding-top: 0;
    padding-bottom: 0;
  }
  [data-row-type="header"] {
    padding-top: $row-padding;
    padding-bottom: $row-padding;
    color:white;
    p {
      color:white;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// CONTENT LAYOUT
main {
  [data-row-type]{
    &:nth-child(1) {
      //padding-top:$row-padding;
    }
    &:nth-last-child(1) {
      padding-bottom:$row-padding;
    }
    &.collapse-columns {
    > .row {
      @extend .collapse;
    }
    }
    &.expand-row {
      > .row {
        @extend .expanded;
      }
    }
    &.padding-top {
      padding-top: $row-padding;
    }
    &.padding-bottom {
      padding-bottom: $row-padding;
    }
    .cell-padding {
      padding:$row-padding/2;
    }
    .cell-padding-top {
      padding-top:$row-padding/2;
    }
    .cell-padding-right {
      padding-right:$row-padding/2;
    }
    .cell-padding-bottom {
      padding-bottom:$row-padding/2;
    }
    .cell-padding-left {
      padding-left:$row-padding/2;
    }
    &[style*="background-image"]{

      //@include object-fit(contain);
      //min-height:800px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top:$row-padding*2;
      padding-bottom:$row-padding*2;
    }
    &.parallax {
      /* Create the parallax scrolling effect */
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      will-change: transform;
    }


  }
  // ORDER COLUMNS
  [data-columns-6-reverse] {
    @include breakpoint(medium){
     .column {
      @include grid-column-position(6);
        + .column {
          @include grid-column-position(-6);
        }
      }
    }
  }

}


body, html {
    height: 100%;
}



p:last-of-type {
  padding-bottom: 1rem;
}

// FOOTER LAYOUT
[data-row-section="footer"] {
  padding-top:$row-padding;
  padding-bottom:$row-padding;
}



// CONTENT COLORS
header, main, footer {
  [data-row-type]{
    &.background-color-red {
      background-color: $primary-color;
      color:white;
      li a {
        font-weight:700;
        color:darken($primary-color, 40);
      }
      label {
        color:white;
      }
      .button {
        background-color: black;
        &:hover {
          background-color: darken($primary-color, 20);
        }
      }
    }
    &.background-color-black {
      background-color: $black;
      color:white;
      li a {
        font-weight:700;
      }
      label {
        color:white;
      }
    }

    &.overlay-row-red > .row {
      background-color: rgba($primary-color,0.9);
      color:white;
      li a {
        font-weight:700;
        color:darken($primary-color, 40);
      }
      label {
        color:white;
      }
      .button {
        background-color: black;
        &:hover {
          background-color: darken($primary-color, 20);
        }
      }
    }

    &.overlay-row-black > .row {
      background-color: rgba($black,0.8);
      color:white;
      li a {
        font-weight:700;
      }
      label {
        color:white;
      }
    }


    .overlay-red {
      background-color: rgba($primary-color,0.8);
      box-shadow:  0.9375rem 0 0 0 rgba($primary-color,0.8),  -0.9375rem 0 0 0 rgba($primary-color,0.8);
      color:white;
      li a {
        font-weight:700;
        color:darken($primary-color, 40);
      }
      label {
        color:white;
      }
      .button {
        background-color: black;
        &:hover {
          background-color: darken($primary-color, 20);
        }
      }
    }

    .overlay-black {
      background-color: rgba($black,0.8);
      box-shadow:  0.9375rem 0 0 0 rgba($black,0.8),  -0.9375rem 0 0 0 rgba($black,0.8);
      color:white;
      li a {
        font-weight:700;
      }
      label {
        color:white;
      }
    }

    .text-white {
      color:white;
    }
  }
}

[data-row-type="email-sign-up"] {
  padding-top:$row-padding;
  padding-bottom:$row-padding;
  background-color: $primary-color;
  color:white;
  .button {
    background-color: black;
    &:hover {
      background-color: darken($primary-color, 20);
    }
  }
}



.navigation-subpages ul {
  padding-top:1rem;
  padding-bottom:1rem;
  text-align: center;
  margin:0 auto;
  li {
    display: inline;
    a {
      display: inline-block;
      font-family: $header-font-family;
      font-weight: 700;
      // /color:white;
      font-size: rem-calc(14px);
      text-transform: uppercase;
      letter-spacing: 0.01rem;
      text-align: center;
    }
  }
}





img + .feature-card {
  padding-top: 2rem;
}



.button {
  //margin-bottom:0;
  + p {
    margin-bottom:1rem;
  }
}



.button {
  text-transform: uppercase;
  font-weight:700;
  font-family: 'Montserrat', sans-serif;
}





.parallax {
    /* The image used */
    background-image: url("/assets/img/carousel-slider/aston-martin-01.jpg");
    /* Set a specific height */
    height: 500px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
