@font-face {
  font-family: 'Supagard-Car-Brands';
  src: url("../fonts/Supagard-Car-Brands.eot?3kue1e");
  src: url("../fonts/Supagard-Car-Brands.eot?3kue1e#iefix") format("embedded-opentype"), url("../fonts/Supagard-Car-Brands.ttf?3kue1e") format("truetype"), url("../fonts/Supagard-Car-Brands.woff?3kue1e") format("woff"), url("../fonts/Supagard-Car-Brands.svg?3kue1e#Supagard-Car-Brands") format("svg");
  font-weight: normal;
  font-style: normal; }

.sg-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Supagard-Car-Brands' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.sg-citroen:before {
  content: ""; }

.sg-lexus:before {
  content: ""; }

.sg-mercedes:before {
  content: ""; }

.sg-peugeot:before {
  content: ""; }
